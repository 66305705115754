import React from 'react';
import css from './DashboardCampaign.module.scss';
import cssDash from '../Dashboard.module.scss';
import Avatar from '../../../components/Avatar/Avatar';
import DashboardCreativity from './DashboardCreativity/DashboardCreativity';
import NotificationsBadge from '../../../components/NotificationsBadge/NotificationsBadge';

interface DashboardCampaignProps {
  campaign: any;
  notifications: any;
}

const DashboardCampaign: React.FC<DashboardCampaignProps> = ({ campaign, notifications }) => {
  return (
    <>
      <div className={css.header}>
        <div
          className={`${cssDash.headerCampaign} ${css.headerContent}`}
          style={{
            backgroundImage: `url('${campaign.logo.path}')`,
          }}
        ></div>
      </div>
      <div className={css.campaignInfo}>
        <div className={css.campaignInfoTitle}>
          <h2>{campaign.name}</h2>
        </div>
        <div className={css.campaignCreativities}>
          {campaign.creativities
            .map((cty: any, i: number) => <DashboardCreativity key={i} creativity={cty} />)
            .slice(0, 3)}
        </div>

        <div className={css.campaignUsers}>
          <div className={css.campaignNotification}>
            <NotificationsBadge notifications={notifications} />
          </div>

          {campaign.internal_team || campaign.external_team ? (
            <div className={css.userAvatar}>
              {campaign.internal_team
                ?.map((u: CampaignUserTeamTypes) => <Avatar key={u.id} user={u} size={40} />)
                .slice(0, 3)}
              {campaign.external_team
                ?.map((u: CampaignUserTeamTypes) => <Avatar key={u.id} user={u} size={40} />)
                .slice(0, 2)}

              {campaign.internal_team.length + campaign.external_team.length > 5 && (
                <>
                  <div className={css.rest}>{`+${
                    campaign.internal_team.length + campaign.external_team.length - 5
                  }`}</div>
                </>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardCampaign;
