import React, { useEffect, useState } from 'react';
import css from './Formats.module.scss';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { getFormats, postAddFormat, putEditFolder, deleteFolder } from '../../../store/formats/api';
import { toggleChatOpen, setInfoOpen } from '../../../store/creativity/slice';
import { RootState } from '../../../store/store';
import { clearAddFormat, clearFolderStatus } from '../../../store/formats/slice';
import Format from './Format/Format';
import AddFormatEditor from './AddFormatEditor/AddFormatEditor';
import SendForApproval from './SendForApproval/SendForApproval';
import { useIsExternalMember, useIsInternalMember, useIsOwner } from '../../../hooks/useRoles';
import Button from '../../../components/UI/Button/Button';
import DropDownMenu from '../../../components/UI/DropDownMenu/DropDownMenu';
import Modal from '../../../components/UI/Modal/Modal';
import Input from '../../../components/UI/Input/Input';
import Icon from '../../../components/UI/Icon/Icon';
import BulkBar from './BulkBar/BulkBar';
import useFeedbackLive from '../../../hooks/useFeedbackLive';

interface FormatsProps {
  folder: FolderTypes;
  creativityId?: number;
  changeSelectedChat: (formatId: number) => void;
}

const Formats: React.FC<FormatsProps> = ({ folder, creativityId, changeSelectedChat }) => {
  const dispatch = useDispatch();

  const isExtMember = useIsExternalMember();
  const isIntMember = useIsInternalMember();
  const isIntOwner = useIsOwner();

  const formats = useSelector((state: RootState) => state.formats.formats);
  const getFormatsState = useSelector((state: RootState) => state.formats.get);
  const addFormatState = useSelector((state: RootState) => state.formats.addFormat);
  const editFolderState = useSelector((state: RootState) => state.formats.editFolder);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const infoOpen = useSelector((state: RootState) => state.creativity.infoOpen);
  const chatOpen = useSelector((state: RootState) => state.creativity.chatOpen);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [selectedFormats, setSelectedFormats] = useState<any[]>([]);
  const [approvalModalOpen, setApprovalModalOpen] = useState<boolean>(false);
  const [isEditFolderOpen, setIsEditFolderOpen] = useState<boolean>(false);
  const [isDeleteFolderOpen, setIsDeleteFolderOpen] = useState<boolean>(false);
  const [visibleFormats, setVisibleFormats] = useState<any[]>([]);
  const [approveFromSingleFormat, setApproveFromSingleFormat] = useState<any>(null);

  const { control, errors, handleSubmit } = useForm<any>();

  useFeedbackLive(folder.id, currentUser.id);

  useEffect(() => {
    if (folder.id) {
      dispatch(getFormats(folder.id));
    }

    return () => {
      setSelectedFormats([]);
    };
  }, [folder.id, dispatch]);

  useEffect(() => {
    if (addFormatState.completed) {
      setShowEditor(false);
      dispatch(clearAddFormat());
    }
  }, [addFormatState.completed, dispatch]);

  useEffect(() => {
    if (editFolderState.completed) {
      setIsEditFolderOpen(false);
      setIsDeleteFolderOpen(false);
      dispatch(clearFolderStatus());
    }
  }, [dispatch, editFolderState.completed]);

  const addFormatHandler = (data: AddFormatDataTypes) => {
    dispatch(postAddFormat({ ...data, creativity_folder_id: folder.id }));
  };

  // Select all formats
  const selectAllFormatsHandler = () => {
    const allSelection: any[] = [];

    if (visibleFormats.length === selectedFormats.length && selectedFormats.length !== 0) {
      setSelectedFormats([]);
    } else {
      visibleFormats.forEach((f: any) => {
        allSelection.push({
          format: f.id,
          version: f.versions.slice(-1).pop().id,
          visibility: f.visibility,
          approvals: f.versions.slice(-1).pop().approvals,
        });
      });
      setSelectedFormats(allSelection);
    }
  };

  useEffect(() => {
    if (selectedFormats.length > 0) {
      const newSelection: any[] = [];
      formats.forEach((format: any) => {
        const sel = selectedFormats.find((f: any) => f.format === format.id);

        if (sel) {
          sel.visibility = format.visibility;
          sel.approvals = format.versions.slice(-1).pop().approvals;
          newSelection.push(sel);
        }
      });
      setSelectedFormats(newSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formats]);

  useEffect(() => {
    let newVisibileFormats = [];
    if (isExtMember) {
      newVisibileFormats = formats.filter(
        (format: any) =>
          format.visibility === 1 ||
          format.versions
            .slice(-1)
            .pop()
            .approvals.find((el: any) => el.user.id === currentUser.id),
      );
    } else {
      newVisibileFormats = [...formats];
    }
    setVisibleFormats(newVisibileFormats);
  }, [currentUser.id, formats, isExtMember]);

  // Select/deselect single format
  const singleFormatSelectionHandler = (format: any, versionId: number) => {
    const newSelection = [...selectedFormats];
    const exist = newSelection.find((f: any) => f.format === format.id);
    if (exist) {
      const index = newSelection
        .map((el: any) => {
          return el.format;
        })
        .indexOf(format.id);
      newSelection.splice(index, 1);
    } else {
      newSelection.push({
        format: format.id,
        version: versionId,
        visibility: format.visibility,
        approvals: format.versions.slice(-1).pop().approvals,
      });
    }
    setSelectedFormats(newSelection);
  };

  const editFolderHandler = (data: any) => {
    if (data.name !== folder.name) {
      dispatch(putEditFolder({ ...data, folderId: folder.id }));
    }
  };

  const deleteFolderHandler = () => {
    dispatch(deleteFolder(Number(folder.id)));
  };

  const closeSendForApprovalModal = () => {
    setApprovalModalOpen(false);
    setApproveFromSingleFormat(null);
  };

  return (
    <div className={cx(css.wrapper, { [css.formatsClose]: infoOpen })}>
      <div className={css.row}>
        <h3 onClick={() => dispatch(setInfoOpen(true))}>{folder.name}</h3>
        <div className={css.actions}>
          <div className={css.actionChat}>
            <Icon icon='chat' gray onClick={() => dispatch(toggleChatOpen())} />
          </div>

          {isIntOwner && (
            <DropDownMenu
              menuItems={[
                {
                  label: 'Rinomina gruppo',
                  icon: 'edit',
                  onClick: () => setIsEditFolderOpen(true),
                },
                {
                  label: 'Elimina gruppo',
                  icon: 'trash',
                  onClick: () => setIsDeleteFolderOpen(true),
                },
              ]}
            />
          )}
        </div>
      </div>

      <div className={css.formatsWrapper}>
        {chatOpen && <div className={css.overlay} onClick={() => dispatch(toggleChatOpen())}></div>}
        <div className={css.newFormatWrapper}>
          <div className={css.allFormats}>
            {visibleFormats.length > 0 && (
              <span
                className={cx(css.selectAll, {
                  [css.selected]: visibleFormats.length === selectedFormats.length,
                })}
                onClick={() => selectAllFormatsHandler()}
              />
            )}
            <div className={css.selectAllLabel}>Formati "{folder.name}"</div>
          </div>
          {!showEditor && isIntMember && (
            <div className={css.newFormat} onClick={() => setShowEditor(true)}>
              <Button icon={{ name: 'add' }} text='Aggiungi un formato' styleTransparent />
            </div>
          )}
        </div>

        {showEditor && (
          <AddFormatEditor
            submit={(data: AddFormatDataTypes) => addFormatHandler(data)}
            cancel={() => setShowEditor(false)}
          />
        )}

        {addFormatState.loading && 'Caricamento'}
        {getFormatsState.loading ? (
          'Caricamento'
        ) : (
          <div className={css.formatsList}>
            {formats.length > 0 && folder.id
              ? formats.map((f: any) => {
                  const haveToApprove = f.versions
                    .slice(-1)
                    .pop()
                    .approvals.find((el: any) => el.user.id === currentUser.id);
                  if (isIntMember || f.visibility || haveToApprove) {
                    return (
                      <Format
                        key={f.id}
                        format={f}
                        folder={folder}
                        changeSelectedChat={() => changeSelectedChat(f.id)}
                        selected={selectedFormats.some(e => e.format === f.id)}
                        sendForApproval={() => {
                          setApproveFromSingleFormat({
                            format: f.id,
                            version: f.versions.slice(-1)[0].id,
                            approvals: f.versions.slice(-1)[0].approvals,
                          });
                          setApprovalModalOpen(true);
                        }}
                        changeSelected={() =>
                          singleFormatSelectionHandler(f, f.versions.slice(-1).pop().id)
                        }
                      />
                    );
                  } else return null;
                })
              : ''}
          </div>
        )}

        {selectedFormats.length > 0 && (
          <BulkBar
            selectedFormats={selectedFormats}
            approveModal={() => setApprovalModalOpen(true)}
            folder={folder}
          />
        )}

        {approvalModalOpen && (
          <SendForApproval
            isOpen={approvalModalOpen}
            closed={closeSendForApprovalModal}
            sended={() => setSelectedFormats([])}
            folder={folder}
            selectedFormats={approveFromSingleFormat ? [approveFromSingleFormat] : selectedFormats}
          />
        )}
      </div>

      <Modal
        title='Rinomina gruppo di formati'
        isOpen={isEditFolderOpen}
        onClose={() => setIsEditFolderOpen(false)}
        submitButton={{ text: 'Salva', inLoad: editFolderState.loading }}
        onSubmit={handleSubmit(editFolderHandler)}
      >
        <div className={css.form}>
          <Controller
            name='name'
            as={Input}
            control={control}
            defaultValue={folder.name}
            rules={{ required: 'Il nome è obbligatorio' }}
            label='Nome del gruppo <span>*</span>'
            error={errors.name ? 1 : 0}
          />
          <span className={css.err}>{errors.name && errors.name.message}</span>
        </div>
      </Modal>

      <Modal
        title='Eliminazione gruppo di formati'
        isOpen={isDeleteFolderOpen}
        onClose={() => setIsDeleteFolderOpen(false)}
        submitButton={{ text: 'Conferma', type: 'danger' }}
        onSubmit={deleteFolderHandler}
      >
        <div className={css.deleteInfo}>
          <div className={css.deleteIcon}>
            <Icon icon='trash' gray />
          </div>
          <p>
            Sei sicuro di voler eliminare il gruppo <strong>"{folder.name}"</strong>?
          </p>
        </div>

        <div className={css.operationsInfo}>
          <p>Tutte le seguenti operazioni non potranno essere ripristinati</p>

          <div>
            <ul>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i formati del gruppo verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti gli allegati e i commenti verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i link di condivisione non saranno raggiungibili
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Formats;
