import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Pusher from 'pusher-js';
import { PusherProvider } from './components/PusherContext/PusherContext';

import Routes from './routes/Routes';

//imposto la lingua globalmente
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

const pusher = new Pusher('df13eb2f2acbd13e72e0', {
  cluster: 'eu',
});

const App = () => {
  return (
    <Provider store={store}>
      <PusherProvider pusher={pusher}>
        <Routes />
      </PusherProvider>
    </Provider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
