import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import css from './Preview.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import Icon from '../../../../../components/UI/Icon/Icon';
import Topbar from '../../../../../components/Topbar/Topbar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { useEffect } from 'react';
import { getFormats, updateFormatVisibility } from '../../../../../store/formats/api';
import { useState } from 'react';
import Chat from '../../../Chat/Chat';
import {
  useIsTeamMember,
  useIsOwner,
  useIsExternalOwner,
  useIsExternalMember,
} from '../../../../../hooks/useRoles';
import ApprovalManager from '../ApprovalManager/ApprovalManager';
import { getCreativity } from '../../../../../store/creativity/api';
import { getFormatMessages } from '../../../../../store/chat/api';
import { getNewFormatMessage } from '../../../../../store/chat/slice';
//import pusher from '../../../../../utils/pusher';
import SendForApproval from '../../SendForApproval/SendForApproval';
import DropDown from '../../../../../components/UI/DropDown/DropDown';
import SimpleApprovalButton from '../../../../../components/SimpleApprovalButton/SimpleApprovalButton';
import Button from '../../../../../components/UI/Button/Button';
import useFeedbackLive from '../../../../../hooks/useFeedbackLive';
import { usePusher } from '../../../../../components/PusherContext/PusherContext';

interface iframePreviewTypes {
  type: string;
  link: string;
  name: string;
  ext: string;
}

type ParamTypes = { creativityId: string; folderId: string; formatId: string };

const Preview: React.FC = () => {
  const pusher = usePusher();
  const dispatch = useDispatch();
  const history = useHistory();

  const isIntOwner = useIsOwner();
  const isExtOwner = useIsExternalOwner();
  const isTeamMember = useIsTeamMember();
  const isExtMember = useIsExternalMember();

  const { folderId, formatId, creativityId } = useParams<ParamTypes>();

  const token = useSelector((state: RootState) => state.user.token);
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const creativity = useSelector((state: RootState) => state.creativity.creativity);
  const formats = useSelector((state: RootState) => state.formats.formats);
  const formatMessages = useSelector((state: RootState) => state.chat.get.formatMessages);
  const currentUser = useSelector((state: RootState) => state.user.user);
  const approveStatus = useSelector((state: RootState) => state.approval.edit);

  const [folder, setFolder] = useState<any>();
  const [formatChat, setFormatChat] = useState(false);
  const [approvalModalOpen, setApprovalModalOpen] = useState(false);
  const [currentFormatForApproval, setCurrentFormatForApproval] = useState<any>();
  const [haveToApprove, setHaveToApprove] = useState<any>({ exist: false, status: '', id: null });

  const [versioningPopup, setVersioningPopup] = useState(false);

  const [format, setFormat] = useState<any>();
  const [currentVersion, setCurrentVersion] = useState<any>();
  const [attachmentsNumber, setAttachmentsNumber] = useState<number>();
  const [attachmentsPopupOpen, setAttachmentsPopupOpen] = useState<boolean>(true);
  const [currentPreview, setCurrentPreview] = useState<any>();
  const [iframePreview, setIframePreview] = useState<iframePreviewTypes | null>();
  const [chatType, setChatType] = useState<'internal' | 'external'>('internal');

  useFeedbackLive(folderId, currentUser.id, Number(formatId));

  useEffect(() => {
    if (isExtMember) {
      setChatType('external');
    }
  }, [isExtMember]);

  useEffect(() => {
    if (isTeamMember !== null) {
      if (!isTeamMember) {
        history.push('/dashboard');
      } else {
        dispatch(getFormats(Number(folderId)));
      }
    }
  }, [folderId, isTeamMember, dispatch, history]);

  useEffect(() => {
    dispatch(getCreativity(Number(creativityId)));
  }, [creativityId, dispatch]);

  useEffect(() => {
    if (creativity.id) {
      const newFolder = creativity.folders.find((folder: any) => folder.id === Number(folderId));

      if (newFolder) {
        setFolder(newFolder);
      }
    }
  }, [creativity, folderId]);

  useEffect(() => {
    if (formats.length > 0) {
      const newFormat: any = formats.find((f: any) => f.id === Number(formatId));
      if (newFormat) {
        setFormat(newFormat);
        setCurrentFormatForApproval({
          approvals: newFormat.versions.slice(-1)[0].approvals,
          format: newFormat.id,
          version: newFormat.versions.slice(-1)[0].id,
        });
        setCurrentVersion(newFormat.versions.slice(-1)[0]);
      }
    }
  }, [formatId, formats]);

  useEffect(() => {
    if (format) {
      if (!formatMessages.find((fm: any) => fm.id === format.id)) {
        dispatch(getFormatMessages(format.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, format]);

  // Check and set preview (if image or <a> tag in content)
  useEffect(() => {
    if (currentVersion) {
      let tempIframePreview;

      if (currentVersion.content.replaceAll('\\', '').includes('href=')) {
        tempIframePreview = {
          type: 'iframe',
          link: currentVersion.content
            .replaceAll('\\', '')
            .match(/<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/)[2],
          name: 'Link',
          ext: 'link',
        };
      }

      if (currentVersion.attachments.length > 0) {
        setCurrentPreview({
          type: 'image',
          link: currentVersion.attachments[0].path,
          name: currentVersion.attachments[0].file_name,
          ext: currentVersion.attachments[0].ext,
        });
      } else if (tempIframePreview) {
        setCurrentPreview(tempIframePreview);
      }

      if (tempIframePreview) {
        setIframePreview(tempIframePreview);
      }

      setAttachmentsNumber(currentVersion.attachments.length + (tempIframePreview ? 1 : 0));
    }
  }, [currentVersion]);

  useEffect(() => {
    if (currentUser && currentVersion && currentVersion.approvals.length > 0) {
      const haveToApprove = currentVersion.approvals.find(
        (el: any) => el.user.id === currentUser.id,
      );

      if (haveToApprove) {
        setHaveToApprove({ exist: true, status: haveToApprove.status, id: haveToApprove.id });
      }
    } else {
      setHaveToApprove({ exist: false, status: '', id: null });
    }
  }, [currentUser, currentVersion]);

  useEffect(() => {
    if (token) {
      const channel = pusher.subscribe('message');
      channel.bind('App\\Events\\NewMessageEvent', getNewMessageHandler);

      return () => {
        channel.unbind('App\\Events\\NewMessageEvent');
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder]);

  const getNewMessageHandler = (data: any) => {
    dispatch(getNewFormatMessage({ formatId: data.data.creativities_format_id, data: data.data }));
  };

  const changeVisibilityHandler = () => {
    dispatch(updateFormatVisibility(format.id, !format.visibility));
  };

  const newCurrentPreviewHandler = (item: any) => {
    setCurrentPreview(item);
  };

  const closeSendForApprovalModal = () => {
    setApprovalModalOpen(false);
  };

  const [referenceElement, setReferenceElement] = useState<Element | null>(null);

  return (
    <>
      <Topbar title={campaign.name} subtitle={folder?.name} />
      <div className={css.content}>
        <div className={css.left}>
          <div className={cx(css.navFormat, css.box)}></div>
        </div>
        <div className={cx(css.mainPreview, css.box)}>
          <div className={css.left}>
            <div className={css.topBar}>
              <div className={css.right}>
                <h3>{currentVersion?.title}</h3>

                <div
                  className={css.versioningBox}
                  onClick={() => setVersioningPopup(true)}
                  ref={setReferenceElement}
                >
                  <span>V{currentVersion?.version}</span>
                  {format?.versions.length > 1 && (
                    <>
                      <Icon icon='chevron-down' size={20} />
                      <DropDown
                        isOpen={versioningPopup}
                        placement='bottom-start'
                        parent={referenceElement}
                        smallMenu
                        onClose={() => {
                          setTimeout(() => {
                            setVersioningPopup(false);
                          }, 100);
                        }}
                      >
                        <ul className={css.versioningContainer}>
                          {format?.versions.map((v: any) => (
                            <li
                              key={v.id}
                              onClick={() => {
                                setCurrentVersion(v);
                                setTimeout(() => {
                                  setVersioningPopup(false);
                                }, 10);
                              }}
                            >
                              Versione {v.version}
                            </li>
                          ))}
                        </ul>
                      </DropDown>
                    </>
                  )}
                </div>

                <div
                  className={css.visibilityBox}
                  onClick={() => {
                    isIntOwner && changeVisibilityHandler();
                  }}
                >
                  <Icon icon={format?.visibility ? 'details' : 'details-off'} gray />
                </div>
              </div>
              <div className={css.right}></div>
            </div>
            <div className={css.bottomBar}>
              <div className={css.left}>
                <div className={css.attachmentsBox}>
                  <Icon icon='papaerclip' gray />
                  <span>{attachmentsNumber}</span>
                </div>
              </div>
              <div className={css.main}>
                <div className={css.mainTop}>
                  <div className={css.topBar}>
                    <div className={css.topBarAttachment}>
                      <span>{currentPreview?.name}</span>
                      {attachmentsNumber && attachmentsNumber > 0 ? (
                        <Icon
                          icon={attachmentsPopupOpen ? 'chevron-up' : 'chevron-down'}
                          gray
                          onClick={() => setAttachmentsPopupOpen(!attachmentsPopupOpen)}
                        />
                      ) : null}
                    </div>
                  </div>
                  {attachmentsNumber && attachmentsNumber > 0 && attachmentsPopupOpen ? (
                    <div className={css.attachmentsListContainer}>
                      <span>Allegati e preview ({attachmentsNumber || ''})</span>
                      <div className={css.previewListImage}>
                        {currentVersion?.attachments.map((image: any) => {
                          if (image.ext === 'pdf') {
                            return (
                              <a
                                key={image.id}
                                href={image.path}
                                target='_blank'
                                rel='noreferrer'
                                className={css.previewSinglePdf}
                              >
                                <div className={css.pspBody}>
                                  <Icon primary icon='papaerclip' size={20} />
                                </div>
                                <div className={css.pspFooter}>{image.name}</div>
                              </a>
                            );
                          }
                          return (
                            <div
                              className={cx(css.previewSingleImage, {
                                [css.previewActive]: image.path === currentPreview?.link,
                              })}
                              key={image.id}
                              onClick={() =>
                                newCurrentPreviewHandler({
                                  type: 'image',
                                  name: image.file_name,
                                  link: image.path,
                                  ext: image.ext,
                                })
                              }
                            >
                              <img src={image.path} alt='' />
                            </div>
                          );
                        })}

                        {iframePreview && (
                          <div
                            className={cx(css.previewSingleIframe, {
                              [css.previewActive]: iframePreview?.link === currentPreview?.link,
                            })}
                            onClick={() => newCurrentPreviewHandler(iframePreview)}
                          >
                            <div className={css.psfBody}>
                              <Icon primary icon='play' size={20} />
                            </div>
                            <div className={css.psfFooter}>
                              {iframePreview.link.length > 16
                                ? iframePreview.link.slice(0, 16) + '..'
                                : iframePreview.link}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}

                  {currentPreview?.type === 'image' ? (
                    <div className={css.imagePreview}>
                      <img src={currentPreview?.link} alt='' />
                    </div>
                  ) : (
                    <a
                      href={currentPreview?.link}
                      className={css.iframePreview}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <iframe src={currentPreview?.link} title='iframe' />
                    </a>
                  )}
                </div>

                <div className={css.approval}>
                  {currentVersion && (
                    <ApprovalManager
                      folderId={folderId}
                      approvals={currentVersion.approvals}
                      showSendForApprovalButton={false}
                      format={format}
                    />
                  )}
                  {(isIntOwner || isExtOwner) && currentVersion?.approvals.length > 0 && (
                    <span style={{ marginLeft: 5, position: 'relative', top: 3 }}>
                      <img
                        src={require('../../../../../assets/images/approval-icon.svg').default}
                        alt=''
                        onClick={() => setApprovalModalOpen(true)}
                        className='cursor-pointer'
                      />
                    </span>
                  )}
                </div>

                {(isIntOwner || isExtOwner) && currentVersion?.approvals.length === 0 && (
                  <div className={css.buttonUnderApprovalWrapper}>
                    <Button
                      text='Richiedi approvazione'
                      onClick={() => setApprovalModalOpen(true)}
                    />
                  </div>
                )}

                {haveToApprove?.exist && (
                  <div className={css.buttonUnderApprovalWrapper}>
                    <SimpleApprovalButton
                      approver={haveToApprove}
                      approvalId={haveToApprove?.id}
                      folderId={folder?.id}
                      inLoad={approveStatus.loading === haveToApprove.id}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={css.right}>
            {!formatChat ? (
              <div className={css.padding}>
                <Icon icon='chat' gray onClick={() => setFormatChat(!formatChat)} />
              </div>
            ) : (
              <div className={css.chatTop}>
                <h3>Chat</h3>{' '}
                <Icon icon='chevron-right' gray onClick={() => setFormatChat(!formatChat)} />
              </div>
            )}

            {formatChat && formatMessages.length > 0 && (
              <div className={css.formatChatContainer}>
                {chatType === 'internal' && (
                  <Chat
                    showTopBar={false}
                    folder={folder}
                    selectedChat={format.id}
                    messages={formatMessages
                      .find((fm: any) => fm.id === format.id)
                      .messages.filter(
                        (m: any) => m.destination_chat === 'internal' || m.type === 'system',
                      )}
                    chatType={chatType}
                    changeChatType={type => setChatType(type)}
                  />
                )}

                {chatType === 'external' && (
                  <Chat
                    showTopBar={false}
                    folder={folder}
                    selectedChat={format.id}
                    messages={formatMessages
                      .find((fm: any) => fm.id === format.id)
                      .messages.filter((m: any) => m.destination_chat === 'external')}
                    chatType={chatType}
                    changeChatType={type => setChatType(type)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={css.right}>
          <div className={cx(css.navFormat, css.box)}></div>
        </div>

        {approvalModalOpen && (
          <SendForApproval
            isOpen={approvalModalOpen}
            closed={closeSendForApprovalModal}
            folder={folder}
            selectedFormats={[currentFormatForApproval]}
          />
        )}
      </div>
    </>
  );
};

export default Preview;
