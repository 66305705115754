import React from 'react';
import css from './Button.module.scss';
import cx from 'classnames';
import Icon from '../Icon/Icon';

interface ButtonProps {
  text: string;
  fullwidth?: boolean;
  styleDanger?: boolean;
  stylePrimary?: boolean;
  styleCancel?: boolean;
  icon?: {
    name: string;
    position?: string;
    onlyIcon?: boolean;
  };
  floatBottomRight?: boolean;
  floatTopRight?: boolean;
  styleTransparent?: boolean;
  dashed?: boolean;
  inLoad?: boolean;
  noHover?: boolean;
}

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      text,
      fullwidth,
      styleDanger,
      stylePrimary,
      styleCancel,
      icon,
      floatBottomRight,
      floatTopRight,
      styleTransparent,
      dashed,
      inLoad,
      noHover,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        ref={ref}
        className={cx(css.button, {
          [css.noHover]: noHover,
          [css.fullwidth]: fullwidth,
          [css.danger]: styleDanger,
          [css.cancel]: styleCancel,
          [css.icon]: icon,
          [css.iconRight]: icon?.position === 'right',
          [css.floatBottomRight]: floatBottomRight,
          [css.floatTopRight]: floatTopRight,
          [css.styleTransparent]: styleTransparent,
          [css.dashed]: dashed,
          [css.inLoad]: inLoad,
          [css.onlyIcon]: icon?.onlyIcon,
        })}
        {...props}
      >
        {inLoad && (
          <div className={css.loading}>
            <Icon icon='refresh' light size={20} />
          </div>
        )}
        {icon && icon.position !== 'right' && (
          <span>
            <Icon icon={icon.name} light={!styleTransparent} gray={styleTransparent} />
          </span>
        )}
        {text}
        {icon && icon.position === 'right' && (
          <span>
            <Icon icon={icon.name} light={!styleTransparent} gray={styleTransparent} />
          </span>
        )}
      </button>
    );
  },
);

//const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ;

export default Button;
