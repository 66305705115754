import React, { useEffect, useState } from 'react';
import css from '../NewCampaign/NewCampaign.module.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { RootState } from '../../store/store';
import { putEditCampaign, deleteCampaign } from '../../store/campaign/api';
import Topbar from '../../components/Topbar/Topbar';
import Input from '../../components/UI/Input/Input';
import Textarea from '../../components/UI/Textarea/Textarea';
import Button from '../../components/UI/Button/Button';
import InputFile from '../../components/UI/InputFile/InputFile';
import Icon from '../../components/UI/Icon/Icon';
import UsersPicker from '../../components/UsersPicker/UsersPicker';
import AddNewUser from '../NewCampaign/AddNewUser/AddNewUser';
import { clearEditCampaignCompleted, clearDeleteCampaign } from '../../store/campaign/slice';
import Modal from '../../components/UI/Modal/Modal';
import filesize from 'filesize';

const EditCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const users = useSelector((state: RootState) => state.users.users);
  const editStatus = useSelector((state: RootState) => state.campaign.edit);
  const deleteStatus = useSelector((state: RootState) => state.campaign.delete);

  const [oldInternalTeam, setOldInternalTeam] = useState<any[]>([]);
  const [oldExternalTeam, setOldExternalTeam] = useState<any[]>([]);
  const [internalTeam, setInternalTeam] = useState<TeamTypes[]>([]);
  const [externalTeam, setExternalTeam] = useState<TeamTypes[]>([]);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [logo, setLogo] = useState<AttachmentsTypes | null>(null);
  const [newUser, setNewUser] = useState<any>();
  const [addUserModal, setAddUserModal] = useState({ isOpen: false, type: 'user', value: '' });
  const [deleteModal, setDeleteModal] = useState(false);

  const { control, errors, setValue, handleSubmit } = useForm<CampaignFormTypes>();

  const addLogoHandler = (files: AttachmentsTypes[]) => {
    setLogo(files[0]);
  };

  const removeLogoHandler = () => {
    setLogo(null);
  };

  const addAttachmentHandler = (files: AttachmentsTypes[]) => {
    const newAttachments = [...attachments];
    files.forEach(el => {
      newAttachments.push(el);
    });
    setAttachments(newAttachments);
  };

  const removeAttachmentHandler = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  useEffect(() => {
    if (!campaign.id) {
      history.push('/dashboard');
    }
  }, [campaign, history]);

  useEffect(() => {
    setValue('name', campaign.name || '');
    setValue('description', campaign.description || '');

    if (campaign.files?.length > 0) {
      setAttachments(campaign.files);
    }

    if (campaign.logo?.path) {
      setLogo(campaign.logo);
    }

    if (campaign.internal_team?.length > 0) {
      setOldInternalTeam(campaign.internal_team);
    }
    if (campaign.external_team?.length > 0) {
      setOldExternalTeam(campaign.external_team);
    }
  }, [campaign, setValue]);

  useEffect(() => {
    if (editStatus.completed) {
      dispatch(clearEditCampaignCompleted());
      history.push(`/dashboard`);
    }
  }, [dispatch, editStatus.completed, history]);

  useEffect(() => {
    if (deleteStatus.completed) {
      dispatch(clearDeleteCampaign());
      history.push(`/dashboard`);
    }
  }, [deleteStatus.completed, dispatch, history]);

  const saveCampaignHandler = (data: CampaignFormTypes) => {
    dispatch(
      putEditCampaign(
        {
          ...data,
          logo: logo,
          files: attachments,
          teams: { internal: internalTeam, external: externalTeam },
        },
        campaign.id,
      ),
    );
  };

  const deleteCampaignHandler = () => {
    dispatch(deleteCampaign(campaign.id));
  };

  return (
    <div className={css.row}>
      <Topbar title='Modifica campagna' />

      <div className={css.wrapper}>
        <div className='container'>
          <div className={css.content}>
            <div className={css.infoCol}>
              <h4>Informazioni della campagna</h4>
              <div className={css.elementWrapper}>
                <Controller
                  name='name'
                  as={Input}
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Il nome è obbligatorio' }}
                  placeholder='Nome della campagna'
                  label='Nome della campagna <span>*</span>'
                  error={errors.name || editStatus.error ? 1 : 0}
                />
                {errors.name && <span className={css.error}>{errors.name.message}</span>}
                {editStatus.error && <span className={css.error}>{editStatus.error}</span>}
              </div>
              <div className={css.elementWrapper}>
                <Controller
                  name='description'
                  as={Textarea}
                  control={control}
                  defaultValue=''
                  label='Descrizione della campagna'
                />
              </div>

              <h4 className={css.fileHeading}>Immagine di copertina</h4>
              <div className={css.elementWrapper}>
                <InputFile
                  name='cover-logo'
                  customInput
                  multiple={false}
                  changed={(files: AttachmentsTypes[]) => addLogoHandler(files)}
                />
                {logo && (
                  <div className={css.fileList}>
                    <div className={css.file}>
                      <Icon icon='papaerclip' gray />
                      <div className={css.fileDetail}>
                        {logo.name}
                        <span>{filesize(logo.size)}</span>
                      </div>
                      <span className={css.fileRemove} onClick={() => removeLogoHandler()}>
                        <Icon icon='remove-circle' gray></Icon>
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <h4 className={css.fileHeading}>File allegati</h4>
              <div className={css.elementWrapper}>
                <InputFile
                  name='files'
                  customInput={true}
                  multiple
                  changed={(files: AttachmentsTypes[]) => addAttachmentHandler(files)}
                />
                {attachments.length > 0 && (
                  <div className={css.fileList}>
                    {attachments.map((file: AttachmentsTypes, index: number) => (
                      <div key={index} className={css.file}>
                        <Icon icon='papaerclip' gray />
                        <div className={css.fileDetail}>
                          {file.name}
                          <span>{filesize(file.size)}</span>
                        </div>
                        <span
                          className={css.fileRemove}
                          onClick={() => removeAttachmentHandler(index)}
                        >
                          <Icon icon='remove-circle' gray></Icon>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className={css.teamCol}>
              <h4>Il team</h4>
              <div className={css.row}>
                <div className={css.col}>
                  <UsersPicker
                    label='Team'
                    changed={(state: TeamTypes[]) => setInternalTeam(state)}
                    currentUsers={oldInternalTeam}
                    newUserModal={(value: string) =>
                      setAddUserModal({ isOpen: true, type: 'user', value })
                    }
                    newUserAdded={newUser && newUser.designation === 'user' && newUser}
                    users={users.filter((user: userTypes) => user.designation === 'user')}
                  />
                </div>
                <div className={css.col}>
                  <UsersPicker
                    label='Team cliente'
                    changed={(state: TeamTypes[]) => setExternalTeam(state)}
                    currentUsers={oldExternalTeam}
                    newUserModal={(value: string) =>
                      setAddUserModal({ isOpen: true, type: 'client', value })
                    }
                    newUserAdded={newUser && newUser.designation === 'client' && newUser}
                    users={users.filter((user: userTypes) => user.designation === 'client')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${css.bottomBar} ${css.multipleElements}`}>
        <div className={css.bottomBarDelete} onClick={() => setDeleteModal(true)}>
          <Icon icon='trash' gray></Icon> <span>Elimina</span>
        </div>

        <div>
          <Button
            style={{ marginRight: '8px' }}
            text='Annulla'
            styleTransparent
            onClick={() => history.push(`/campaign/${campaign.id}`)}
          />
          <Button
            text='Salva'
            onClick={handleSubmit(saveCampaignHandler)}
            inLoad={editStatus.loading}
          />
        </div>
      </div>

      <Modal
        title='Eliminazione campagna'
        isOpen={deleteModal}
        onClose={() => setDeleteModal(false)}
        submitButton={{ text: 'Conferma', type: 'danger', inLoad: deleteStatus.loading }}
        onSubmit={deleteCampaignHandler}
      >
        <div className={css.deleteInfo}>
          <div className={css.deleteIcon}>
            <Icon icon='trash' gray />
          </div>
          <p>
            Sei sicuro di voler eliminare la campagna <strong>"{campaign.name}"</strong>?
          </p>
        </div>
        <div className={css.operationsInfo}>
          <p>Tutte le seguenti operazioni non potranno essere ripristinati</p>

          <div>
            <ul>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutte le cratività verranno eliminate
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i formati e i commenti verranno eliminati
              </li>
              <li>
                <span>
                  <Icon icon='check' light />
                </span>
                Tutti i link di condivisione non saranno raggiungibili
              </li>
            </ul>
          </div>
        </div>
      </Modal>

      <AddNewUser
        isOpen={addUserModal.isOpen}
        designation={addUserModal.type}
        closed={() => setAddUserModal({ ...addUserModal, isOpen: false })}
        newUserAdded={newUser => setNewUser(newUser)}
        inputValue={addUserModal.value}
      />
    </div>
  );
};

export default EditCampaign;
