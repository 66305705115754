import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFormats } from '../store/formats/api';
import { usePusher } from '../components/PusherContext/PusherContext';

const useFeedbackLive = (folderId: any, currentUserId: number | null, formatId: any = null) => {
  const pusher = usePusher();
  const dispatch = useDispatch();

  useEffect(() => {
    const channel = pusher.subscribe(`feedbacklive.${folderId}`);
    channel.bind('App\\Events\\FeedbackLiveEvent', (data: any) => {
      if (data.data.user_id !== currentUserId) {
        if (formatId !== null) {
          if (formatId === data.data.creativities_format_id) {
            dispatch(getFormats(folderId, false));
          }
        } else {
          if (folderId) dispatch(getFormats(folderId, false));
        }
      }
    });

    return () => {
      channel.unbind('App\\Events\\FeedbackLiveEvent');
    };
  }, [currentUserId, dispatch, folderId, formatId]);
};

export default useFeedbackLive;
