import React from 'react';
import css from './Notification.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import Avatar from '../../../Avatar/Avatar';
import moment from 'moment';

interface NotificationProps {
  notification: any;
  campaign: any;
  closed: () => void;
}

const Notification: React.FC<NotificationProps> = ({ notification, campaign, closed }) => {
  return (
    <li onClick={closed}>
      <Link
        to={{
          pathname:
            notification.type === 'campaign_deleted'
              ? '/dashboard'
              : notification.type === 'new_campaign'
              ? `/campaign/${notification.campaign_id}`
              : notification.creativity_id !== 0 && notification.creativity_folder_id === 0
              ? `/campaign/${notification.campaign_id}`
              : notification.creativities_format_id !== 0
              ? `/campaign/${notification.campaign_id}/creativity/${notification.creativity_id}/folder/${notification.creativity_folder_id}/preview/${notification.creativities_format_id}`
              : `/campaign/${notification.campaign_id}/creativity/${notification.creativity_id}/folder/${notification.creativity_folder_id}`,
          state: {
            creativities_format_id: notification.creativities_format_id,
            creativity_folder_id: notification.creativity_folder_id,
            type: notification.type,
          },
        }}
      >
        <div
          className={cx(css.messageWrapper, {
            [css.readed]: notification.read === 1,
          })}
        >
          <small>{campaign?.name}</small>
          <div className={css.messageContent}>
            <div className={css.avatarWrapper}>
              <Avatar
                user={{
                  owner: notification.from_user_owner,
                  name: `${notification.from_user_name} ${notification.from_user_surname}`,
                }}
              />
            </div>
            <div className={css.messageInfo}>
              <strong>{`${notification.from_user_name} ${notification.from_user_surname} `}</strong>
              <span>{notification.notification}</span>
              <small className={css.messageData}>{moment(notification.created_at).fromNow()}</small>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default Notification;
