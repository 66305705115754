import React, { useEffect, useState } from 'react';
import css from './ApprovalPopup.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import Icon from '../../../../../../components/UI/Icon/Icon';
import DropDown from '../../../../../../components/UI/DropDown/DropDown';
import Button from '../../../../../../components/UI/Button/Button';
import moment from 'moment';
import { clearApprovalStatus } from '../../../../../../store/approval/slice';
import { RootState } from '../../../../../../store/store';
import Avatar from '../../../../../../components/Avatar/Avatar';

interface ApprovalPopupProps {
  approver: any;
  multiple: boolean;
  approveLoading: boolean;
  canApprove: { status: boolean; isOwner?: boolean };
  changedStatus: (newStatus: string) => void;
  deleteApprovalRequest?: () => void;
}

const ApprovalPopup: React.FC<ApprovalPopupProps> = ({
  approver,
  changedStatus,
  approveLoading,
  canApprove,
  multiple,
  deleteApprovalRequest,
}) => {
  const dispatch = useDispatch();

  const approveStatus = useSelector((state: RootState) => state.approval.edit);

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
  const [approvalPopupOpen, setApprovalPopupOpen] = useState<boolean>(false);

  // vedi: https://popper.js.org/react-popper/v2/react-portals/

  const [referenceElement, setReferenceElement] = useState<Element | null>(null);
  const [referenceElement2, setReferenceElement2] = useState<Element | null>(null);

  useEffect(() => {
    if (
      approveStatus.completed === approver.id ||
      (multiple && approveStatus.completed === 'multiple')
    ) {
      setDropDownOpen(false);
      setApprovalPopupOpen(false);
      dispatch(clearApprovalStatus());
    }
  }, [approveStatus.completed, approver.id, dispatch]);

  const deleteApprovalRequestHandler = () => {
    if (deleteApprovalRequest) {
      deleteApprovalRequest();
    }
    setDropDownOpen(false);
  };

  return (
    <>
      <div
        ref={setReferenceElement}
        className={css.approverBox}
        onClick={e => {
          e.preventDefault();
          return canApprove.status ? setDropDownOpen(!dropDownOpen) : null;
        }}
      >
        <div className={css.image}>
          <Avatar user={approver.user} size={32} />
        </div>

        {canApprove.status && <Icon icon={`chevron-${dropDownOpen ? 'up' : 'down'}`} gray />}

        <div
          className={cx(css.status, {
            [css.waiting]: approver.status === 'new' || approver.status === 'for_approval',
            [css.approved]: approver.status === 'approved',
            [css.rejected]: approver.status === 'rejected',
          })}
        />
      </div>

      {canApprove.status && (
        <DropDown
          isOpen={dropDownOpen}
          placement='top-start'
          style={{ minWidth: 324 }}
          parent={referenceElement}
          onClose={() => {
            setTimeout(() => {
              setDropDownOpen(false);
            }, 500);
          }}
        >
          <div className={css.approvalDropDownWrapper} onClick={e => e.preventDefault()}>
            <div className={css.approvalDropDownTop}>
              <Icon icon='calendar' gray />
              <span>Richiesta inviata il {moment(approver.created_at).format('DD.MM.YYYY')}</span>
            </div>
            <div className={css.approvalDropDownBody}>
              <div className={css.image}>
                <Avatar user={approver.user} size={32} />
              </div>
              <div className={css.approver}>
                <div
                  className={css.approverName}
                >{`${approver.user.name} ${approver.user.surname}`}</div>
                <div className={css.approverRole}>{approver.user.type}</div>
              </div>
            </div>
            <div className={css.status}>
              <div className={css.approverStatus}>
                {approver.status === 'new' || approver.status === 'for_approval' ? (
                  <span className={css.waiting}>In approvazione</span>
                ) : approver.status === 'approved' ? (
                  <span className={css.approved}>Hai approvato</span>
                ) : approver.status === 'rejected' ? (
                  <span className={css.rejected}>Non hai approvato</span>
                ) : null}
              </div>
            </div>
            <div className={css.approvalDropDownBottom}>
              <DropDown
                isOpen={approvalPopupOpen}
                placement='top-end'
                parent={referenceElement2}
                smallMenu
                onClose={() => setApprovalPopupOpen(false)}
              >
                <div className={css.approvalActionsWrapper}>
                  <div className={css.approve}>
                    <div onClick={() => changedStatus('approved')}>
                      <Icon icon='check' green />
                      <span>Approva</span>
                    </div>
                  </div>
                  <div className={css.disapprove}>
                    <div onClick={() => changedStatus('rejected')}>
                      <Icon icon='remove-circle' red />
                      <span>Non Approvare</span>
                    </div>
                  </div>
                </div>
              </DropDown>

              <Button
                ref={setReferenceElement2}
                text={
                  canApprove.isOwner
                    ? 'Approva a suo nome'
                    : approver.status === 'new'
                    ? 'In attesa di tua approvazione'
                    : 'Modifica la tua approvazione'
                }
                icon={{ name: `chevron-${approvalPopupOpen ? 'up' : 'down'}`, position: 'right' }}
                inLoad={approveLoading}
                onClick={() => setApprovalPopupOpen(!approvalPopupOpen)}
              />

              {approver.status === 'new' && canApprove.isOwner && (
                <div className={css.deleteRequest} onClick={() => deleteApprovalRequestHandler()}>
                  <Icon icon='trash' gray />
                  <span>Elimina richiesta</span>
                </div>
              )}
            </div>
          </div>
        </DropDown>
      )}
    </>
  );
};

export default ApprovalPopup;
