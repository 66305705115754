import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import css from './inputDate.module.scss';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Icon from '../Icon/Icon';

const InputDate = React.forwardRef((props: any, ref: React.Ref<ReactDatePickerProps>) => {
  const days = ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'];
  const months = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];

  const locale = {
    localize: {
      day: (n: any) => days[n],
      month: (n: any) => months[n],
    },
    formatLong: {
      date: () => 'mm/dd/yyyy',
    },
  };

  const CustomDateInput = React.forwardRef((props: any, ref: any) => (
    <div className={css.wrapper}>
      <input
        className={css.input}
        onClick={props.onClick}
        onChange={props.onChange}
        value={props.value}
        ref={ref}
      />
      <Icon icon='calendar' gray onClick={props.onClick} />
    </div>
  ));

  return (
    <>
      {props.label && <label className='label'>{props.label}</label>}
      <DatePicker locale={locale} ref={ref} customInput={<CustomDateInput />} {...props} />
    </>
  );
});

export default InputDate;
