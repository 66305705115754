import React, { useEffect } from 'react';
import css from './NotificationToast.module.scss';
import Icon from '../UI/Icon/Icon';
import Avatar from '../Avatar/Avatar';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeToastNotification } from '../../store/notifications/slice';
import { hasReadNotifications } from '../../store/notifications/api';

interface NotificationsToastProps {
  notification: any;
}

const NotificationToast: React.FC<NotificationsToastProps> = ({ notification }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const showTimer = setTimeout(() => removeToastHandler(notification.id), 3000);

    return () => {
      clearTimeout(showTimer);
    };
  }, []);

  const removeToastHandler = (id: number) => {
    dispatch(removeToastNotification(id));
  };

  return (
    <div className={css.notificationToast}>
      <div className={css.breadcrumb}>
        <small>
          {notification.campaign_name && notification.campaign_name}
          {notification.creativity_type && ' / ' + notification.creativity_type}
          {notification.creativity_folder_name && ' / ' + notification.creativity_folder_name}
        </small>
        <Icon icon='close' primary onClick={() => removeToastHandler(notification.id)} />
      </div>
      <div className={css.body}>
        <Link
          to={{
            pathname:
              notification.type === 'campaign_deleted'
                ? '/dashboard'
                : notification.type === 'new_campaign'
                ? `/campaign/${notification.campaign_id}`
                : notification.creativity_id !== 0 && notification.creativity_folder_id === 0
                ? `/campaign/${notification.campaign_id}`
                : notification.creativities_format_id !== 0
                ? `/campaign/${notification.campaign_id}/creativity/${notification.creativity_id}/folder/${notification.creativity_folder_id}/preview/${notification.creativities_format_id}`
                : `/campaign/${notification.campaign_id}/creativity/${notification.creativity_id}/folder/${notification.creativity_folder_id}`,
            state: {
              creativities_format_id: notification.creativities_format_id,
              creativity_folder_id: notification.creativity_folder_id,
              type: notification.type,
            },
          }}
          onClick={() => {
            removeToastHandler(notification.id);
            dispatch(hasReadNotifications([notification.id]));
          }}
        >
          <div className={css.messageContent}>
            <div className={css.avatarWrapper}>
              <Avatar
                user={{
                  owner: notification.from_user_owner,
                  surname: notification.from_user_surname,
                  name: notification.from_user_name,
                }}
                size={40}
              />
            </div>
            <div className={css.messageInfo}>
              <strong>{`${notification.from_user_name} ${notification.from_user_surname} `}</strong>
              <span>{notification.notification}</span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default NotificationToast;
