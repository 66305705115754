import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import DropDown from '../UI/DropDown/DropDown';
import Icon from '../UI/Icon/Icon';
import css from '../../views/Creativity/Formats/Format/ApprovalManager/ApprovalPopup/ApprovalPopup.module.scss';
import { useDispatch } from 'react-redux';
import { changeApprovalStatus } from '../../store/approval/api';

interface SimpleApprovalButtonProps {
  approvalId: number;
  folderId: number;
  approver: any;
  inLoad: boolean;
}

const SimpleApprovalButton: React.FC<SimpleApprovalButtonProps> = ({
  approvalId,
  folderId,
  approver,
  inLoad,
}) => {
  const dispatch = useDispatch();

  const [approvalPopupOpen, setApprovalPopupOpen] = useState<boolean>(false);

  const [referenceElement, setReferenceElement] = useState<Element | null>(null);

  const changedStatus = (status: string) => {
    dispatch(changeApprovalStatus(approvalId, folderId, status));
  };

  return (
    <>
      <DropDown
        isOpen={approvalPopupOpen}
        placement='top-end'
        parent={referenceElement}
        smallMenu
        onClose={() => setApprovalPopupOpen(false)}
      >
        <div className={css.approvalActionsWrapper}>
          <div className={css.approve}>
            <div onClick={() => changedStatus('approved')}>
              <Icon icon='check' green />
              <span>Approva</span>
            </div>
          </div>
          <div className={css.disapprove}>
            <div onClick={() => changedStatus('rejected')}>
              <Icon icon='remove-circle' red />
              <span>Non Approvare</span>
            </div>
          </div>
        </div>
      </DropDown>
      <Button
        ref={setReferenceElement}
        text={
          approver.status === 'new'
            ? 'In attesa di tua approvazione'
            : 'Modifica la tua approvazione'
        }
        icon={{ name: `chevron-${approvalPopupOpen ? 'up' : 'down'}`, position: 'right' }}
        inLoad={inLoad}
        onClick={() => setApprovalPopupOpen(!approvalPopupOpen)}
      />
    </>
  );
};

export default SimpleApprovalButton;
