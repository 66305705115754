import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import css from './EditProfile.module.scss';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import { postEditUser } from '../../store/user/api';
import Icon from '../../components/UI/Icon/Icon';
import { NavLink } from 'react-router-dom';
import { postLogout } from '../../store/user/slice';

const EditProfile: React.FC = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.user);
  const editState = useSelector((state: RootState) => state.user.edit);

  const { control, errors, handleSubmit, setValue } = useForm<ProfileFormTypes>();

  const onSubmit = (data: ProfileFormTypes) => {
    dispatch(postEditUser(data));
  };

  useEffect(() => {
    setValue('name', user.name);
    setValue('surname', user.surname);
    setValue('email', user.email);
  }, [user, setValue]);

  return (
    <div className={css.container}>
      <div className={css.accountMenu}>
        <div className={css.accountMenuTop}>
          <h3>Il tuo account</h3>
        </div>
        <div>
          <ul>
            <li>
              <NavLink to='/profile'>
                <Icon icon='profile' gray></Icon>
                <span>Il tuo profilo</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/signIn' onClick={() => dispatch(postLogout())}>
                <Icon icon='log-out' gray></Icon>
                <span>Logout</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div className={css.accountInfo}>
        <div className={css.accountInfoTop}>
          <div></div>
          <h3>Modifica il tuo profilo</h3>
          <div className='logoClient'>
            <img src={require('../../assets/images/logo-telepass.png').default} alt='' />
          </div>
        </div>
        <div className={css.accountInfoMain}>
          <div className={css.accountInfoMainWrapper}>
            <div className={css.accountInfoMainEdit}>
              <h4>Informazioni personali</h4>
              {/* <div>
                <Icon icon='edit' gray />
              </div> */}
            </div>
            <div className={css.accountInfoMainBottom}>
              <div className={css.accountInfoAvatar}>
                <div className={css.accountInfoEdit}>
                  <Icon icon='edit' gray />
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div className={css.accountInfoBottom}>
          <div className={css.accountInfoBottomDelete}>
            <Icon icon='trash' gray />
            <span>Elimina</span>
          </div>
          <div className={css.accountInfoBottomActions}>
            <Button text='Annulla' styleTransparent style={{ marginRight: 8 }} />
            <Button text='Salva' />
          </div>
        </div>
      </div>

      {/* <img src='https://via.placeholder.com/150' alt='' />
      <ul>
        <li>Nome: {user.name}</li>
        <li>Cognome:{user.surname}</li>
        <li>Ruolo: {user.type}</li>
        <li>Email: {user.email}</li>
      </ul>
      <Link to='/editprofile'>Modifica profilo</Link> */}
    </div>
    // <div style={{ width: 500 }}>
    //   <h3>Modifica profilo</h3>
    //   <img src='https://via.placeholder.com/150' alt='' />
    //   <form onSubmit={handleSubmit(onSubmit)}>
    //     <Controller
    //       name='name'
    //       control={control}
    //       defaultValue={user.name}
    //       as={Input}
    //       label='Nome'
    //       rules={{ required: 'Il nome è obbligatorio' }}
    //     />
    //     <span>{errors.name && errors.name.message}</span>
    //     <Controller
    //       name='surname'
    //       control={control}
    //       defaultValue={user.surname}
    //       as={Input}
    //       label='Cognome'
    //     />
    //     <Controller
    //       name='email'
    //       control={control}
    //       defaultValue={user.email}
    //       as={Input}
    //       label='Email'
    //       rules={{ required: "L'email è obbligatoria" }}
    //     />
    //     <span>{errors.email && errors.email.message}</span>
    //     <Button text='modifica' />
    //     {editState.loading && 'Caricamento'}
    //     {editState.error && editState.error}
    //   </form>
    // </div>
  );
};

export default EditProfile;
