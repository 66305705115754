import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const notificationsStore = createSlice({
  name: 'notifications',
  initialState: {
    isPanelOpen: false,
    notifications: [] as any[],
    notificationsToast: [] as any[],
  },
  reducers: {
    getNotificationsSuccess(state, action: PayloadAction<any>) {
      state.notifications = action.payload;
    },
    newNotification(state, action: PayloadAction<any>) {
      state.notifications = [action.payload, ...state.notifications];
      state.notificationsToast = [action.payload, ...state.notificationsToast];
    },
    removeToastNotification(state, action: PayloadAction<number>) {
      state.notificationsToast = state.notificationsToast.filter(
        notification => notification.id !== action.payload,
      );
    },
    togglePanelOpen(state) {
      state.isPanelOpen = !state.isPanelOpen;
    },
    setPanelOpen(state, action: PayloadAction<boolean>) {
      state.isPanelOpen = action.payload;
    },
  },
});

export const {
  getNotificationsSuccess,
  newNotification,
  removeToastNotification,
  togglePanelOpen,
  setPanelOpen,
} = notificationsStore.actions;
