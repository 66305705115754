import React, { useState, useEffect, useCallback, useRef } from 'react';
import css from './UsersPicker.module.scss';
import Input from '../UI/Input/Input';
import Icon from '../UI/Icon/Icon';
import Avatar from '../Avatar/Avatar';

interface UserPickerTypes extends userTypes {
  id: number;
  owner: boolean;
}

interface UserPickerProps {
  users: userTypes[];
  changed: (state: TeamTypes[]) => void;
  newUserModal?: (value: string) => void;
  defaultOwner?: any;
  ownership?: boolean;
  label?: string;
  currentUsers?: any;
  newUserAdded?: any;
}

const UsersPicker: React.FC<UserPickerProps> = ({
  users,
  defaultOwner,
  ownership = true,
  currentUsers,
  changed,
  newUserModal,
  label,
  newUserAdded,
}) => {
  const node = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserPickerTypes[]>([]);
  const [searchHasChild, setSearchHasChild] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const elementRef = useCallback(
    node => {
      if (node !== null) {
        if (node.childNodes.length > 0) {
          setSearchHasChild(false);
        } else {
          setSearchHasChild(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue],
  );

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (newUserAdded) {
      const newUser = { ...newUserAdded };
      if (selectedUsers.length === 0) {
        newUser.owner = true;
      } else {
        newUser.owner = false;
      }
      setSelectedUsers([...selectedUsers, newUser]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserAdded]);

  // Set default ownership to current user logged
  useEffect(() => {
    if (defaultOwner && defaultOwner.id) {
      setSelectedUsers([{ ...defaultOwner, owner: true }]);
    }
  }, [defaultOwner]);

  // On selectedUser changes call changed prop in parent component
  useEffect(() => {
    const ordSelected: any = [];
    selectedUsers.forEach((el: UserPickerTypes) => {
      ordSelected.push({ user_id: el.id, owner: el.owner, designation: el.designation });
    });
    changed(ordSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  useEffect(() => {
    if (currentUsers) {
      const upUsers = [...currentUsers];
      const newUsers: any[] = [];
      upUsers.forEach((u: any) => {
        newUsers.push({ ...u });
      });
      setSelectedUsers(newUsers);
    }
  }, [currentUsers]);

  const selectedUserHandler = (user: UserPickerTypes) => {
    setInputValue('');
    setIsOpen(false);
    const newSelection = [...selectedUsers];

    if (ownership) {
      if (selectedUsers.length === 0 && user.designation === 'client') {
        newSelection.push({ ...user, owner: true });
      } else {
        newSelection.push({ ...user, owner: false });
      }
    } else {
      newSelection.push({ ...user });
    }

    setSelectedUsers(newSelection);
  };

  const removedUserHandler = (userId: number) => {
    setSelectedUsers(selectedUsers.filter((user: UserPickerTypes) => user.id !== userId));
  };

  // Change owner of campaign
  const changeOwnerHandler = (userId: number) => {
    const upInternalselectedUsers = [...selectedUsers];
    //upInternalselectedUsers[0].owner = false;
    upInternalselectedUsers.forEach((user: UserPickerTypes) => {
      user.owner = false;
    });
    let upOwner = selectedUsers.find((user: UserPickerTypes) => user.id === userId);
    if (upOwner) {
      upOwner.owner = true;
    }
    setSelectedUsers(upInternalselectedUsers);
  };

  const newUserModalHandler = () => {
    if (newUserModal) {
      newUserModal(inputValue);
      setInputValue('');
      setIsOpen(false);
    }
  };

  const handleClickOutside = (e: any) => {
    if (node?.current?.contains(e.target)) {
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={css.wrapper} ref={node}>
      <Input
        name='internalUsers'
        value={inputValue}
        onChange={e => inputChangeHandler(e)}
        autoComplete='off'
        onFocus={() => setIsOpen(true)}
        label={label}
        icon='add-user'
      />

      {isOpen && (
        <div className={css.modal}>
          <div className={css.modalBody}>
            <ul ref={elementRef}>
              {users.map((user: any) => {
                // Check if user is already selected
                if (!selectedUsers.some((m: UserPickerTypes) => m.id === user.id)) {
                  // Check if name/surname of user includes inputValue string
                  if (
                    `${user.name} ${user.surname}`.toLowerCase().includes(inputValue.toLowerCase())
                  ) {
                    return (
                      <li key={user.id} onClick={() => selectedUserHandler(user)}>
                        {user.name + ' ' + user.surname}
                      </li>
                    );
                  }
                }
                return null;
              })}
            </ul>
            {searchHasChild && <span>Nessun risultato per "{inputValue}"</span>}
          </div>

          {newUserModal && (
            <div className={css.modalFooter}>
              <span onClick={() => newUserModalHandler()}>{`CREA "${inputValue}"`}</span>
            </div>
          )}
        </div>
      )}

      <ul className={css.selectedUsers}>
        {selectedUsers.map((user: UserPickerTypes) => (
          <li key={user.id}>
            <div className={css.selectedImage}>
              <Avatar user={user} size={40} />
            </div>
            <div className={css.selectedInfo}>
              <div>
                <span className={css.selectedName}>
                  {`${user.name} ${user.surname}`}
                  {user.id === defaultOwner?.id && ' (Tu)'}
                </span>
                {user.owner && <span className={css.ownerBadge}>Owner</span>}
              </div>
              <span className={css.selectedRole}>{user.type}</span>
            </div>

            <div className={css.selectedOptions}>
              {ownership && !user.owner && (
                <span onClick={() => changeOwnerHandler(user.id)}>
                  <Icon icon='ownership' gray />
                </span>
              )}
              {ownership ? (
                !user.owner && (
                  <span onClick={() => removedUserHandler(user.id)}>
                    <Icon icon='trash' gray />
                  </span>
                )
              ) : (
                <span onClick={() => removedUserHandler(user.id)}>
                  <Icon icon='trash' gray />
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersPicker;
