import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/UI/Input/Input';
import Select from '../../../components/UI/Select/Select';
import Button from '../../../components/UI/Button/Button';
import { useForm, Controller } from 'react-hook-form';
import css from './Info.module.scss';
import InputDate from '../../../components/UI/InputDate/InputDate';
import Parse from 'html-react-parser';

const Info: React.FC<InfoProps> = ({ changeStep, info, updateInfo }) => {
  const history = useHistory();

  const { register, control, errors, handleSubmit, setValue } = useForm<any>();

  const [endDate, setEndDate] = useState() as any;

  const onSubmit = (data: any) => {
    changeStep();
    updateInfo({ ...data, endDate });
  };

  useEffect(() => {
    if (info.name) {
      setValue('name', info.name);
    }
    if (info.type) {
      setValue('type', { ...info.type });
    }
  }, [info, setValue]);

  return (
    <div>
      <form className={css.content} onSubmit={handleSubmit(onSubmit)}>
        <h3>Informazioni della creatività</h3>
        <div className={css.formContent}>
          <div className={css.input}>
            <Controller
              name='name'
              as={Input}
              control={control}
              defaultValue=''
              placeholder='Nome creatività'
              rules={{ required: 'Il nome è obbligatorio' }}
              label='Nome nuova creatività <span>*</span>'
            />
            <span className={css.error}>{errors.name && errors.name.message}</span>
          </div>
          <div className={css.input}>
            <Controller
              name='type'
              as={Select}
              control={control}
              defaultValue=''
              options={[
                { value: 'video', label: 'Video' },
                { value: 'banner', label: 'Banner' },
                { value: 'social', label: 'Social' },
              ]}
              label='Tipologia <span>*</span>'
            />
            <span className={css.error}>{errors.type && errors.type.message}</span>
          </div>

          <div className={css.input} style={{ marginTop: '17px' }}>
            <InputDate
              name='date'
              onChange={(newDate: Date) => setEndDate(newDate)}
              dateFormat='dd/MM/yyyy'
              selected={endDate}
              label='Fine prevista'
            />
          </div>
        </div>
        <div className={css.footer}>
          <div className={css.footerContent}>
            <Button
              text='Annulla'
              styleTransparent
              onClick={() => history.goBack()}
              style={{ marginRight: 12 }}
            />
            <Button text='Continua' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Info;
