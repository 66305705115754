import React, { useEffect, useState } from 'react';
import filesize from 'filesize';
import css from './NewCampaign.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../store/store';
import { postAddCampaign } from '../../store/campaign/api';
import { clearAddCampaignCompleted } from '../../store/campaign/slice';
import Input from '../../components/UI/Input/Input';
import Textarea from '../../components/UI/Textarea/Textarea';
import InputFile from '../../components/UI/InputFile/InputFile';
import Button from '../../components/UI/Button/Button';
import UsersPicker from '../../components/UsersPicker/UsersPicker';
import AddNewUser from './AddNewUser/AddNewUser';
import Topbar from '../../components/Topbar/Topbar';
import Icon from '../../components/UI/Icon/Icon';

const NewCampaign: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const currentUser = useSelector((state: RootState) => state.user.user);
  const users = useSelector((state: RootState) => state.users.users);
  const addState = useSelector((state: RootState) => state.campaign.add);

  const [internalTeam, setInternalTeam] = useState<TeamTypes[]>([]);
  const [externalTeam, setExternalTeam] = useState<TeamTypes[]>([]);
  const [attachments, setAttachments] = useState<AttachmentsTypes[]>([]);
  const [logo, setLogo] = useState<AttachmentsTypes | null>(null);
  const [newUser, setNewUser] = useState<any>();
  const [addUserModal, setAddUserModal] = useState({ isOpen: false, type: 'user', value: '' });

  const { control, errors, handleSubmit } = useForm<CampaignFormTypes>();

  const onSubmit = (data: CampaignFormTypes) => {
    dispatch(
      postAddCampaign({
        ...data,
        logo: logo,
        files: attachments,
        teams: { internal: internalTeam, external: externalTeam },
      }),
    );
  };

  const addLogoHandler = (files: AttachmentsTypes[]) => {
    setLogo(files[0]);
  };

  const removeLogoHandler = () => {
    setLogo(null);
  };

  const addAttachmentHandler = (files: AttachmentsTypes[]) => {
    const newAttachments = [...attachments];
    files.forEach(el => {
      newAttachments.push(el);
    });
    setAttachments(newAttachments);
  };

  const removeAttachmentHandler = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  };

  useEffect(() => {
    if (addState.completed) {
      history.push('/dashboard');
      dispatch(clearAddCampaignCompleted());
    }
  }, [addState.completed, dispatch, history]);

  return (
    <>
      <Topbar title='Creazione nuova campagna' />
      <div className={css.wrapper}>
        <div className='container'>
          <div className={css.content}>
            <div className={css.infoCol}>
              <h4>Informazioni della campagna</h4>
              <div className={css.elementWrapper}>
                <Controller
                  name='name'
                  as={Input}
                  control={control}
                  defaultValue=''
                  rules={{ required: 'Il nome è obbligatorio' }}
                  placeholder='Nome della campagna'
                  label='Nome della campagna <span>*</span>'
                  error={errors.name || addState.error ? 1 : 0}
                />
                {errors.name && <span className={css.error}>{errors.name.message}</span>}
                {addState.error && <span className={css.error}>{addState.error}</span>}
              </div>
              <div className={css.elementWrapper}>
                <Controller
                  name='description'
                  as={Textarea}
                  control={control}
                  defaultValue=''
                  label='Descrizione della campagna'
                />
              </div>

              <h4 className={css.fileHeading}>Immagine di copertina</h4>
              <div className={css.elementWrapper}>
                <InputFile
                  name='cover-logo'
                  customInput
                  multiple={false}
                  changed={(files: AttachmentsTypes[]) => addLogoHandler(files)}
                />
                {logo && (
                  <div className={css.fileList}>
                    <div className={css.file}>
                      <Icon icon='papaerclip' gray />
                      <div className={css.fileDetail}>
                        {logo.name}
                        <span>{filesize(logo.size)}</span>
                      </div>
                      <span className={css.fileRemove} onClick={() => removeLogoHandler()}>
                        <Icon icon='remove-circle' gray></Icon>
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <h4 className={css.fileHeading}>File allegati</h4>
              <div className={css.elementWrapper}>
                <InputFile
                  name='files'
                  customInput
                  multiple
                  changed={(files: AttachmentsTypes[]) => addAttachmentHandler(files)}
                />
                {attachments.length > 0 && (
                  <div className={css.fileList}>
                    {attachments.map((file: AttachmentsTypes, index: number) => (
                      <div key={index} className={css.file}>
                        <Icon icon='papaerclip' gray />
                        <div className={css.fileDetail}>
                          {file.name}
                          <span>{filesize(file.size)}</span>
                        </div>
                        <span
                          className={css.fileRemove}
                          onClick={() => removeAttachmentHandler(index)}
                        >
                          <Icon icon='remove-circle' gray></Icon>
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className={css.teamCol}>
              <h4>Il team</h4>
              <div className={css.row}>
                <div className={css.col}>
                  <UsersPicker
                    label='Team'
                    changed={(state: TeamTypes[]) => setInternalTeam(state)}
                    defaultOwner={currentUser}
                    newUserModal={(value: string) =>
                      setAddUserModal({ isOpen: true, type: 'user', value })
                    }
                    newUserAdded={newUser && newUser.designation === 'user' && newUser}
                    users={users.filter((user: userTypes) => user.designation === 'user')}
                  />
                </div>
                <div className={css.col}>
                  <UsersPicker
                    label='Team cliente'
                    changed={(state: TeamTypes[]) => setExternalTeam(state)}
                    newUserModal={(value: string) =>
                      setAddUserModal({ isOpen: true, type: 'client', value })
                    }
                    newUserAdded={newUser && newUser.designation === 'client' && newUser}
                    users={users.filter((user: userTypes) => user.designation === 'client')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={css.bottomBar}>
        <Button text='Crea campagna' inLoad={addState.loading} onClick={handleSubmit(onSubmit)} />
      </div>

      <AddNewUser
        isOpen={addUserModal.isOpen}
        designation={addUserModal.type}
        closed={() => setAddUserModal({ ...addUserModal, isOpen: false })}
        newUserAdded={newUser => setNewUser(newUser)}
        inputValue={addUserModal.value}
      />
    </>
  );
};

export default NewCampaign;
