import React, { useEffect, useState } from 'react';
import css from '../ChatList.module.scss';
import moment from 'moment';

interface SingleChatListProps {
  onSelected: Function;
  format?: any;
  lastMessage: any;
  title: string;
  messageCounter: number;
}

const SingleChatList: React.FC<SingleChatListProps> = ({
  onSelected,
  format,
  title,
  lastMessage,
  messageCounter,
}) => {
  const [formattedMessage, setFormattedMessage] = useState('');

  useEffect(() => {
    if (lastMessage?.message) {
      const message = lastMessage.message;

      const findAll = message.match(/@(.*?)\)/g);
      let finalMessage = message;

      if (findAll) {
        findAll.forEach((element: string, index: number) => {
          const find = element.match(/\[(.*?)\]/);

          if (find) {
            if (index === 0) {
              finalMessage = message.replace(element, `@${find[1]}`);
            } else {
              finalMessage = finalMessage.replace(element, `@${find[1]}`);
            }
          }
        });
      }
      setFormattedMessage(finalMessage);
    }
  }, [lastMessage]);

  return (
    <li className={css.chatName} onClick={() => onSelected(format?.id || 0)}>
      <div className={css.chatNameLeft}>
        {messageCounter > 0 ? <strong>{title}</strong> : <p>{title}</p>}
        <small>
          {formattedMessage
            ? formattedMessage.length > 25
              ? formattedMessage.substring(0, 25) + '...'
              : formattedMessage
            : 'Nessun messaggio'}
        </small>
      </div>
      <div className={css.chatNameRight}>
        {lastMessage?.created_at ? (
          <small>{moment(lastMessage.created_at).format('HH:mm')}</small>
        ) : null}
        {messageCounter > 0 && <span className={css.unreadBadge}>{messageCounter}</span>}
      </div>
    </li>
  );
};

export default SingleChatList;
