import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import css from './Creativity.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { getCreativity } from '../../store/creativity/api';
import CreativityInfo from './CreativityInfo/CreativityInfo';
import Formats from './Formats/Formats';
import { RootState } from '../../store/store';
import Topbar from '../../components/Topbar/Topbar';
import ChatManager from './ChatManager/ChatManager';
import { useIsTeamMember } from '../../hooks/useRoles';
import { hasReadNotifications } from '../../store/notifications/api';

type ParamTypes = { creativityId: string; folderId: string };

const Creativity: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const isTeamMember = useIsTeamMember();

  const { creativityId, folderId } = useParams<ParamTypes>();

  const creativities = useSelector((state: RootState) => state.creativities.creativities);
  const creativity = useSelector((state: RootState) => state.creativity.creativity);
  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const chatOpen = useSelector((state: RootState) => state.creativity.chatOpen);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [selectedChat, setSelectedChat] = useState<null | number>(null);
  const [currentFolder, setCurrentFolder] = useState<FolderTypes>({
    id: null,
    name: '',
    creativity_id: null,
  });

  useEffect(() => {
    if (folderId && creativity.folders) {
      const newFolder = creativity.folders.find((folder: any) => folder.id === Number(folderId));

      if (newFolder) {
        setCurrentFolder(newFolder);
      } else {
        setCurrentFolder({ id: null, name: '', creativity_id: null });
      }
    }
  }, [creativity.folders, folderId]);

  useEffect(() => {
    if (isTeamMember !== null) {
      if (!isTeamMember) {
        history.push('/dashboard');
      } else {
        dispatch(getCreativity(Number(creativityId)));
      }
    }
  }, [creativityId, isTeamMember, creativities, dispatch, history]);

  useEffect(() => {
    if (location.state && location.state.type === 'message') {
      if (location.state.creativities_format_id) {
        setSelectedChat(location.state.creativities_format_id);
      } else {
        setSelectedChat(0);
      }
    }
  }, [location]);

  useEffect(() => {}, [currentFolder]);

  useEffect(() => {
    const listToRead: any[] = [];
    notifications.forEach((notification: any) => {
      if (
        notification.creativity_id === Number(creativityId) &&
        notification.type === 'step_changed' &&
        notification.read === 0
      ) {
        listToRead.push(notification.id);
      }
    });

    if (listToRead.length > 0) {
      dispatch(hasReadNotifications(listToRead));
    }
  }, [creativityId, dispatch, notifications]);

  return (
    <>
      <Topbar title={campaign.name} backLink={`/campaign/${campaign.id}`} />
      <div className={cx(css.wrapper, { [css.chatOpen]: chatOpen })}>
        <CreativityInfo
          creativity={creativity}
          folder={currentFolder}
          changeCurrent={(folder: FolderTypes) =>
            history.push(`/campaign/${campaign.id}/creativity/${creativity.id}/folder/${folder.id}`)
          }
        />
        {currentFolder.id && (
          <>
            <Formats
              folder={currentFolder}
              creativityId={creativity.id}
              changeSelectedChat={(formatId: number) => setSelectedChat(formatId)}
            />
            <ChatManager folder={currentFolder} changeSelectedChat={selectedChat} />
          </>
        )}
      </div>
    </>
  );
};

export default Creativity;
