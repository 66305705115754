import React, { useEffect, useState, useRef } from 'react';
import cx from 'classnames';
import css from './Chat.module.scss';
import mentionsCss from './Mentions.module.scss';
import { RootState } from '../../../store/store';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../components/UI/Button/Button';
import {
  deleteFormatMessage,
  deleteFolderMessage,
  postNewFolderMessage,
  postNewFormatMessage,
  readChatMessages,
} from '../../../store/chat/api';
import { MentionsInput, Mention } from 'react-mentions';
import Icon from '../../../components/UI/Icon/Icon';
import Avatar from '../../../components/Avatar/Avatar';
import Message from './Message/Message';
import { hasReadNotifications } from '../../../store/notifications/api';
import { useIsInternalMember } from '../../../hooks/useRoles';
//import DropDownMenu from '../../../components/UI/DropDownMenu/DropDownMenu';

interface ChatProps {
  folder: FolderTypes;
  onClose?: Function;
  messages?: any;
  showTopBar?: boolean;
  selectedChat: null | number;
  chatType: 'internal' | 'external';
  changeChatType?: (chat: any) => any;
}

interface UserMention {
  id: number;
  display: string;
  role: string;
}

const Chat: React.FC<ChatProps> = ({
  folder,
  onClose,
  messages,
  selectedChat,
  showTopBar = true,
  chatType,
  changeChatType,
}) => {
  const isIntMember = useIsInternalMember();
  const dispatch = useDispatch();

  const campaign = useSelector((state: RootState) => state.campaign.campaign);
  const user = useSelector((state: RootState) => state.user.user);
  const getAll = useSelector((state: RootState) => state.chat.get);
  const formats: any = useSelector((state: RootState) => state.formats.formats);
  const notifications = useSelector((state: RootState) => state.notifications.notifications);

  const [message, setMessage] = useState('');
  const [mentionsList, setMentionsList] = useState<UserMention[]>([]);
  const [currentFormat, setCurrentFormat] = useState<any>();

  const messageRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: any) => {
    setMessage(e.target.value);
  };

  const deleteMessageHandler = (messageId: number) => {
    if (selectedChat === 0 && folder.id) {
      dispatch(deleteFolderMessage(messageId, folder.id));
    }
    if (selectedChat) {
      dispatch(deleteFormatMessage(messageId, selectedChat));
    }
  };

  useEffect(() => {
    const listToRead: any[] = [];
    notifications.forEach((notification: any) => {
      if (
        notification.creativities_format_id === selectedChat &&
        notification.type === 'message' &&
        notification.read === 0
      ) {
        listToRead.push(notification.id);
      }
    });
    if (listToRead.length > 0) {
      dispatch(hasReadNotifications(listToRead));
    }
  }, [dispatch, notifications, selectedChat]);

  useEffect(() => {
    const current = formats.find((f: any) => f.id === selectedChat);
    if (current) {
      setCurrentFormat(current);
    } else {
      setCurrentFormat('general');
    }
  }, [formats]);

  useEffect(() => {
    if (campaign.id) {
      const newMentions: UserMention[] = [];
      const userList = [...campaign.internal_team, ...campaign.external_team];
      userList.forEach((user: any) => {
        if (user.id) {
          newMentions.push({
            id: user.id,
            display: `${user.name} ${user.surname}`,
            role: user.type,
          });
        }
      });
      setMentionsList(newMentions);
    }
  }, [campaign]);

  useEffect(() => {
    if (messages.length > 0)
      dispatch(
        readChatMessages(messages[0].creativities_format_id, messages[0].creativity_folder_id),
      );
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!message) {
      messageRef?.current?.focus();
    }
    if (folder.id && selectedChat !== null) {
      if (selectedChat === 0) {
        dispatch(postNewFolderMessage(message, folder.id, chatType));
      } else {
        dispatch(
          postNewFormatMessage(
            message,
            selectedChat,
            chatType,
            messages[0].creativities_format_id,
            folder.id,
          ),
        );
      }
    }
    setMessage('');
  };

  return (
    <div className={css.wrapper}>
      {showTopBar && (
        <div className={css.singleChatRow}>
          <div className={css.chatBack}>
            <Icon icon='back' gray onClick={() => onClose && onClose()} />
          </div>

          {selectedChat === 0 ? (
            <h3>General</h3>
          ) : (
            <h3>
              {formats.find((f: any) => f.id === selectedChat)?.versions.slice(-1)[0].title.length >
              43
                ? formats
                    .find((f: any) => f.id === selectedChat)
                    ?.versions.slice(-1)[0]
                    .title.substring(0, 43) + '...'
                : formats.find((f: any) => f.id === selectedChat)?.versions.slice(-1)[0].title}
              {}
            </h3>
          )}
          <div></div>
        </div>
      )}

      {isIntMember && (currentFormat === 'general' || currentFormat.visibility) ? (
        <ul className={css.doubleTabs}>
          <li
            className={cx(chatType === 'internal' && css.active)}
            onClick={() => (changeChatType ? changeChatType('internal') : null)}
          >
            Crafted
          </li>
          <li
            className={cx(chatType === 'external' && css.active)}
            onClick={() => (changeChatType ? changeChatType('external') : null)}
          >
            TBusiness
          </li>
        </ul>
      ) : null}

      <div className={css.messages}>
        {getAll.loading ? (
          'Caricamento'
        ) : (
          <ul>
            {messages.length > 0 &&
              messages.map((msg: any, i: number) =>
                msg.type === 'system' ? (
                  <li className={css.fromSystem} key={i}>
                    {msg.message}
                  </li>
                ) : msg.user.id === user.id ? (
                  <li className={css.fromMe} key={i}>
                    <div className={css.content}>
                      <div className={css.contentTop}>
                        <small className={css.date}>{moment(msg.created_at).format('HH:mm')}</small>
                        <div className={css.contentTopIcon}>
                          {/* <Icon icon='more-horizontal' gray /> */}
                        </div>
                        {/* <div className={css.contentTopIcon}>
                        <DropDownMenu
                          smallMenu
                          menuItems={[
                            {
                              label: 'Elimina',
                              onClick: () => deleteMessageHandler(msg.id),
                            },
                          ]}
                        />
                      </div> */}
                      </div>
                      <div className={css.message}>
                        <Message message={msg.message} />
                      </div>
                    </div>
                    <div className={css.userImage}>
                      <Avatar user={user} size={32} />
                    </div>
                  </li>
                ) : (
                  <li className={css.fromOther} key={i}>
                    <div className={css.userImage}>
                      <Avatar user={msg.user} />
                    </div>
                    <div className={css.content}>
                      <div className={css.author}>
                        <strong>{`${msg.user.name} ${msg.user.surname}`}</strong>
                        <small className={css.date}>{moment(msg.created_at).format('HH:mm')}</small>
                      </div>
                      <div className={css.message}>
                        <Message message={msg.message} />
                      </div>
                    </div>
                  </li>
                ),
              )}
          </ul>
        )}
      </div>
      <form className={css.newMessageWrapper} onSubmit={(e: any) => onSubmit(e)}>
        <MentionsInput
          style={{ width: '100%' }}
          value={message}
          inputRef={messageRef}
          className='mentions'
          allowSuggestionsAboveCursor
          classNames={mentionsCss}
          onChange={(e: any) => handleChange(e)}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              onSubmit(e);
            }
          }}
        >
          <Mention
            trigger='@'
            data={mentionsList}
            className={mentionsCss.mentions__mention}
            renderSuggestion={(suggestion: any, search, highlightedDisplay) => (
              <div className={mentionsCss.itemInner}>
                <div className={mentionsCss.itemAvatar}></div>
                <div className={mentionsCss.itemInfo}>
                  <h4>{suggestion.display}</h4>
                  <span>{suggestion.role}</span>
                </div>
              </div>
            )}
          />
        </MentionsInput>

        <Button icon={{ name: 'send' }} text='' noHover />
      </form>
    </div>
  );
};

export default Chat;
